export default {
  "forms": {
    "error-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to submit your request. Please try again."])},
    "success-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your support request has been submitted!"])},
    "error-msg-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name is required"])},
    "error-msg-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A valid email is required"])},
    "error-msg-comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments are required"])},
    "error-msg-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must agree to the privacy policy"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We thrive when coming up with innovative ideas but also understand that a smart concept should be supported with measurable results."])},
    "contact-form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your subject..."])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message..."])},
      "agree-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send message"])}
    }
  },
  "support": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can we help?"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you're having trouble accessing a Lighthousing product, there's a chance we're currently experiencing a temporary problem. However, feel free to send us a message and we will provide the needed help. "])},
    "support-form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product name..."])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe your issue..."])},
      "agree-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree to"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send message"])}
    }
  },
  "about-us": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Lighthousing.io! We are dedicated to providing innovative solutions to help you navigate your digital landscape with ease. Our team is passionate about technology, committed to excellence, and always ready to go the extra mile for our clients. Meet the people behind Lighthousing.io:"])},
    "jacob": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Founder"])},
      "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With over two decades of experience in the tech industry, Jacob Suñol has been at the forefront of digital health innovations. Having worked with industry giants like Abbott and Roche, Jacob brings a wealth of knowledge and expertise to Lighthousing.io. Specializing in digital health, Jacob is passionate about leveraging technology to improve healthcare outcomes. A strong background in computer science, anthropology, and human evolution provides a unique perspective that drives Jacob's approach to problem-solving and innovation. Outside of work, Jacob loves building things, playing video games, and skiing. Jacob is also an advocate for healthy living, embracing a gluten-free, low-carb lifestyle. At Lighthousing.io, Jacob is committed to fostering a culture of creativity, collaboration, and continuous improvement. Jacob believes in the power of technology to transform lives and is dedicated to making a positive impact through every project."])}
    }
  },
  "features": {
    "p1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A digital atelier creating modern & engaging online experiences"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Star Lab helps bring any idea to life. No matter the complexity level, if it can impact millions of people we will make it happen."])},
      "points": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We put a lot of effort in thinking."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coding is an art."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good products are designed for the end-user."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iterate over and over 10.000 times."])},
        
      ],
      "action-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])}
    },
    "p2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Lab, a laboratory for dreamers"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an idea that will impact millions of people, contact us. Let's work together to make it happen."])},
      "action-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])}
    }
  },
  "footer": {
    "subscribe": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
      "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every month we will be sending curated content to help on your digital journey."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
    },
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])}
  },
  "section": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "starlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Lab"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies policy"])}
  },
  "services": {
    "list": {
      "s1": {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-phone"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Health"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are experts building digital health solutions for the industry. From conception to production. We can work with your team, setup an independent team or enhance your capabilities. We work on SaMD class IIb."])}
      },
      "s7": {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-compass"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer experience"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We design to impact humans. By putting people first, we build experiences that will redefine your industry. From paper, music, to VR the limit doesn't exist."])}
      },
      "s2": {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-science"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We combine our expertise in technology with the right architecture and innovation. We specialize in Javascript, Cloud, web and mobile development. We work on regulated environments following your QMS or applying ours."])}
      },
      "s3": {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-graph1"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ML and Artificial Intelligence"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to drive a data project. We can provide you with any help, from helping on the business issue, data set selection, curation, analysis, modelling, visualization. We can help you also with the privacy topics in mostly any part of the world."])}
      },
      "s4": {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-culture"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business architecture"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to increase business opportunities by using digital? We provide services around leadership in innovation, strategy development, business architecture, and organizational transformation."])}
      },
      "s5": {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-helm"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interim execs"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For companies that are experiencing leadership gaps (CIO, CTO, CDO, CSO, DEO), we can provide interim executives to step in to get a company on track, ultimately hiring their permanent replacement."])}
      }
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We craft products, business and technology to add value for our clients and their customers."])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We help companies launch their amazing products"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We provide light to companies and individuals by helping use disruptive technologies. We can help with any step of product development, from ideation to development. We can also help enhance your internal team by coaching, training and hiring. We specialize in disruptive products that help can positively impact millions of people."])},
    "video-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RnDC9MXSqCY"])}
  }
}