

<template>
  <div>
    <router-view />
  </div>
</template>

<style>
@import "https://fonts.googleapis.com/css?family=Poppins:400,500,700|Rubik:300,400,500";
</style>