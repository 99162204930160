export default {
  "forms": {
    "error-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No s'ha pogut enviar la teva sol·licitud. Torna-ho a intentar."])},
    "success-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La teva sol·licitud de suport s'ha enviat!"])},
    "error-msg-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nom és obligatori"])},
    "error-msg-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es requereix un correu electrònic vàlid"])},
    "error-msg-comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es requereixen comentaris"])},
    "error-msg-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has d'acceptar la política de privacitat"])}
  },
  "about-us": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvinguts a Lighthousing.io! Ens dediquem a proporcionar solucions innovadores per ajudar-te a navegar pel teu paisatge digital amb facilitat. El nostre equip és apassionat per la tecnologia, compromès amb l'excel·lència i sempre disposat a donar el millor de si per als nostres clients. Coneix les persones darrere de Lighthousing.io:"])},
    "jacob": {
      "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amb més de dues dècades d'experiència en la indústria tecnològica, Jacob Suñol ha estat a l'avantguarda de les innovacions en salut digital. Haver treballat amb gegants de la indústria com Abbott i Roche, Jacob aporta una gran quantitat de coneixements i experiència a Lighthousing.io. Especialitzat en salut digital, Jacob és apassionat per aprofitar la tecnologia per millorar els resultats de salut. Una sòlida formació en informàtica, antropologia i evolució humana proporciona una perspectiva única que impulsa l'enfocament de Jacob per resoldre problemes i innovar. Fora de la feina, a Jacob li encanta construir coses, jugar a videojocs i esquiar. Jacob també és un defensor de la vida saludable, adoptant un estil de vida sense gluten i baix en carbohidrats. A Lighthousing.io, Jacob està compromès a fomentar una cultura de creativitat, col·laboració i millora contínua. Jacob creu en el poder de la tecnologia per transformar vides i està dedicat a fer un impacte positiu en cada projecte."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundador"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre Nosaltres"])}
  },
  "contact": {
    "contact-form": {
      "agree-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepto la"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El teu missatge..."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vostra adreça electrònica"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El teu nom"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envia el missatge"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assumpte ..."])}
    },
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ens agrada quan ens proposeu idees innovadores, però també entenem que s’hauria de donar suport a un concepte intel·ligent amb resultats mesurables."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacte'ns"])}
  },
  "features": {
    "p1": {
      "action-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Més informació"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nostre Star Lab ajuda a donar vida a qualsevol idea. No importa el nivell de complexitat, si pot afectar milions de persones el farem realitat."])},
      "points": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posem molt esforç en la concepció."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programar és un art."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Els bons productes estan dissenyats per a l'usuari final."])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeteix més de 10.000 vegades."])},
        
      ],
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un taller digital que crea experiències en línia modernes i atractives"])}
    },
    "p2": {
      "action-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacta amb nosaltres"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tens una idea que afectarà milions de persones, contacta amb nosaltres. Treballem junts per aconseguir-ho."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Lab, un laboratori per a somiadors"])}
    }
  },
  "footer": {
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacta amb nosaltres"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informació"])},
    "subscribe": {
      "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cada mes enviarem contingut seleccionat per ajudar-vos en la vostra aventura digital."])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correu electrònic"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriure's"])}
    }
  },
  "home": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il·luminem a empreses i particulars ajudant a utilitzar tecnologies disruptives. Podem ajudar en qualsevol pas del desenvolupament del producte, des de la ideació fins al desenvolupament. També podem ajudar a millorar el vostre equip intern mitjançant coaching, formació i amb la contractació. Ens especialitzem en productes disruptius que poden ajudar a impactar positivament a milions de persones."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajudem les empreses a llançar els seus increïbles productes"])},
    "video-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RnDC9MXSqCY"])}
  },
  "section": {
    "Star Lab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Lab"])},
    "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre nosaltres"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacte"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de cookies"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inici"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privadesa"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serveis"])},
    "starlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Lab"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suport"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condicions"])}
  },
  "services": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborem productes, construïm empreses i tecnologia per afegir valor als nostres clients i als seus clients."])},
    "list": {
      "s1": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som experts en la creació de solucions de salut digital per a la indústria. De la concepció a la producció. Podem treballar amb el vostre equip, configurar un equip independent o incrementar les vostres capacitats. Treballem fins a SaMD classe IIb."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-phone"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Health"])}
      },
      "s2": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combinem la nostra experiència en tecnologia amb l’arquitectura i la innovació adequades. Som especialistes en desenvolupament Javascript, Cloud, web i mòbil. Treballem en entorns regulats seguint el vostre SGQ o aplicant el nostre."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-science"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tecnologia"])}
      },
      "s3": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voleu impulsar un projecte de dades. Podem proporcionar-vos qualsevol ajuda, des d’ajudar-vos en qüestions empresarials, selecció de conjunts de dades, conservació, anàlisi, modelització i visualització. També us podem ajudar amb els temes de privadesa en quasi qualsevol part del món."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-graph1"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ML i Intel·ligència Artificial"])}
      },
      "s4": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voleu augmentar les oportunitats de negoci mitjançant l’ús digital? Oferim serveis al voltant del lideratge en innovació, desenvolupament d’estratègies, arquitectura de negocis i transformació organitzativa."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-culture"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquitectura empresarial"])}
      },
      "s5": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per a les empreses que pateixen mancances de lideratge (CIO, CTO, CDO, CSO, DEO), podem oferir als executius interins que intervinguin per aconseguir encarrilar l'empresa, contractant finalment el seu substitut permanent."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-helm"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executius interins"])}
      },
      "s6": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tens una idea, ets expert en una àrea i vols innovar? Som aquí per ajudar-vos. També trobem diverses solucions del nostre StarLab."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-plane"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accelerador d'empreses"])}
      },
      "s7": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dissenyem per impactar els humans. Posant la gent en primer lloc, creem experiències que redefiniran la vostra indústria. Des del paper, la música i la realitat virtual, el límit no existeix."])},
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-compass"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experiència de consumidor"])}
      }
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Els nostres serveis"])}
  },
  "support": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si teniu problemes per accedir a un producte Lighthousing, és probable que actualment experimentem un problema temporal. Tot i això, no dubteu a enviar-nos un missatge i us proporcionarem l’ajuda necessària."])},
    "support-form": {
      "agree-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepto la"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriviu el vostre problema ..."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vostra adreça electrònica"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El teu nom"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom del producte..."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envia el missatge"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com et podem ajudar?"])}
  }
}