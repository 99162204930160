import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue } from 'bootstrap-vue'
import VueParticles from 'vue-particles'
import axios from 'axios'
import VueAxios from 'vue-axios'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
  apiKey: '0dd36673e18b8f6ace2274b77c3215be',
  plugins: [new BugsnagPluginVue()]
})

import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from './i18n'

// Vue tracking
const bugsnagVue = Bugsnag.getPlugin('vue')

// Create app
const app = createApp(App)

app.use(bugsnagVue)

app.use(VueParticles)
app.use(BootstrapVue)

app.use(i18n)
app.use(router)
app.use(VueAxios, axios)

app.mount('#app')