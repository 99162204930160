export default {
  "forms": {
    "error-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交请求失败。请再试一次。"])},
    "success-msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的支持请求已提交！"])},
    "error-msg-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名为必填项"])},
    "error-msg-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要有效的电子邮件地址"])},
    "error-msg-comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论为必填项"])},
    "error-msg-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须同意隐私政策"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们不仅擅长提出创新想法，还深知一个智能的概念必须有可衡量的结果支持。"])},
    "contact-form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的姓名"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的电子邮件"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的主题..."])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的消息..."])},
      "agree-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我同意"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送消息"])}
    }
  },
  "support": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们能帮什么忙？"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您在访问 Lighthousing 产品时遇到困难，我们可能正在经历暂时的问题。不过，欢迎随时向我们发送消息，我们会提供所需的帮助。"])},
    "support-form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的姓名"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的电子邮件"])},
      "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品名称..."])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述您的问题..."])},
      "agree-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我同意"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送消息"])}
    }
  },
  "about-us": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎来到 Lighthousing.io！我们致力于提供创新的解决方案，帮助您轻松驾驭数字世界。我们的团队热爱技术，致力于卓越，始终准备为客户竭尽全力。请认识 Lighthousing.io 背后的人物："])},
    "jacob": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创始人"])},
      "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jacob Suñol 拥有超过二十年的科技行业经验，一直走在数字健康创新的前沿。曾在 Abbott 和 Roche 等行业巨头工作，Jacob 为 Lighthousing.io 带来了丰富的知识和专业知识。Jacob 专注于数字健康，热衷于利用技术改善医疗保健成果。他在计算机科学、人类学和人类进化方面的扎实背景，为 Jacob 的问题解决和创新方法提供了独特的视角。在工作之余，Jacob 热衷于构建事物、玩电子游戏和滑雪。Jacob 还提倡健康生活，秉持无麸质、低碳水化合物的生活方式。在 Lighthousing.io，Jacob 致力于培育创造力、协作和持续改进的文化。Jacob 相信技术改变生活的力量，并通过每一个项目致力于产生积极影响。"])}
    }
  },
  "features": {
    "p1": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个数字工作室，创造现代且引人入胜的在线体验"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的 Star Lab 可以让任何想法成真。无论复杂程度如何，如果它可以影响数百万人，我们都会实现它。"])},
      "points": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们在思考上投入了大量精力。"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编码是一门艺术。"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好的产品是为最终用户设计的。"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不断迭代 10,000 次。"])},
        
      ],
      "action-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
    },
    "p2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Lab，一个为梦想家打造的实验室"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您有一个将影响数百万人的想法，请联系我们。让我们一起努力实现它。"])},
      "action-btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])}
    }
  },
  "footer": {
    "subscribe": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅"])},
      "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个月我们都会发送精选内容，帮助您踏上数字旅程。"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])}
    },
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息"])}
  },
  "section": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务"])},
    "starlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Star Lab"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持"])},
    "about-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条款"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies 政策"])}
  },
  "services": {
    "list": {
      "s1": {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-phone"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字健康"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们是数字健康解决方案的专家。从构思到生产，我们可以与您的团队合作，组建独立团队或提升您的能力。我们专注于 SaMD IIb 类产品。"])}
      },
      "s7": {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-compass"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户体验"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们设计以人为本的体验，通过优先考虑人类需求，我们构建了可以重新定义您行业的体验。无论是纸质、音乐还是 VR，极限不存在。"])}
      },
      "s2": {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-science"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技术"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们将技术专业知识与正确的架构和创新结合在一起。我们专注于 JavaScript、云技术、网页和移动开发。我们在受监管的环境中工作，遵循您的质量管理体系（QMS）或使用我们的体系。"])}
      },
      "s3": {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-graph1"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["机器学习与人工智能"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["想要推动数据项目？我们可以为您提供帮助，从业务问题的解答、数据集的选择、策划、分析、建模到可视化。我们还可以帮助您处理世界上几乎任何地区的隐私问题。"])}
      },
      "s4": {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-culture"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商业架构"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["想通过数字技术增加商业机会？我们提供关于创新领导力、战略发展、业务架构和组织转型的服务。"])}
      },
      "s5": {
        "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pe-7s-helm"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["临时高管"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对于面临领导层空缺的公司（CIO、CTO、CDO、CSO、DEO），我们可以提供临时高管，帮助公司重新走上正轨，最终聘请其永久替代者。"])}
      }
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的服务"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们设计产品、业务和技术，为客户及其客户增值。"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们帮助公司推出他们的惊人产品"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们通过帮助使用颠覆性技术为公司和个人提供支持。我们可以帮助产品开发的任何阶段，从构思到开发。我们还可以通过指导、培训和招聘来帮助提升您的内部团队。我们专注于可以积极影响数百万人生活的颠覆性产品。"])},
    "video-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RnDC9MXSqCY"])}
  }
}