import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/index9.vue')
  },
  {
    path: '/star-lab',
    name: 'Star Lab',
    component: () => import('../views/starlab.vue')
  },
  {
    path: '/legal/accessibility',
    name: 'Accessibility',
    component: () => import('../views/accessibility.vue')
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('../views/support.vue')
  },
  {
    path: '/about-us',
    name: 'About Us',
    component: () => import('../views/aboutUs.vue')
  },
  {
    path: '/legal/:document',
    name: 'Legal',
    component: () => import('../views/legal.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    else {
      const position = {};

      if (to.hash) {
        position.el = to.hash;
        position.left = 0;
        position.top = 30;
        position.behavior = 'smooth';
        if (document.querySelector(to.hash)) {
          return position;
        }
        return false;
      }
    }
  }
})

export default router
